import React, { useState, useEffect } from "react";
import {
  ConContactItem,
  ConRightItem,
  Container,
  MobileMenu,
  NavCon,
} from "./style";
import logo from "../assets/logo.png";
import MobileNav from "./mobile";

const Navbar = () => {
  // const [showNavbar, setShowNavbar] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setShowNavbar(window.scrollY > 0);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <Container
    // showNavbar={showNavbar}
    // onMouseEnter={() => setShowNavbar(true)}
    // onMouseLeave={() => setShowNavbar(false)}
    >
      <NavCon to="/">
        <img src={logo} alt="logo" width={150} style={{ cursor: "pointer" }} />
      </NavCon>

      <ConRightItem>
        <NavCon to="/company-info">
          <p>회사소개</p>
        </NavCon>
      </ConRightItem>
      <ConRightItem>
        <NavCon to="/work-service">
          <p>외국인 생애 지원 서비스</p>
        </NavCon>
      </ConRightItem>
      <ConRightItem>
        <NavCon to="/study-service">
          <p>유학생 지원 서비스</p>
        </NavCon>
      </ConRightItem>
      <ConRightItem>
        <NavCon to="/corporate-service">
          <p>기업맞춤형 외국인고용 서비스</p>
        </NavCon>
      </ConRightItem>
      <ConRightItem>
        <NavCon to="/regional-service">
          <p>지역혁신 연구소</p>
        </NavCon>
      </ConRightItem>

      <ConContactItem>
        <NavCon to="/">
          <p>문의하기</p>
        </NavCon>
      </ConContactItem>
      <MobileMenu>
        <MobileNav />
      </MobileMenu>
    </Container>
  );
};

export default Navbar;
