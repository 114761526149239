import React, { useRef } from "react";

import { useEffect } from "react";
import styled from "styled-components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import logo from "../../assets/logo.png";

const KakaoMap = () => {
  const new_script = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.addEventListener("load", () => {
        resolve();
      });
      script.addEventListener("error", (e) => {
        reject(e);
      });
      document.head.appendChild(script);
    });
  };

  useEffect(() => {
    const my_script = new_script(
      "https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=4ef400ca395d8997a2216abdd59e712b"
    );
    my_script.then(() => {
      console.log("script loaded!!!");
      const kakao = window["kakao"];
      kakao.maps.load(() => {
        const mapContainer = document.getElementById("map");
        const options = {
          center: new kakao.maps.LatLng(35.841486, 128.760341),
          level: 3,
        };
        const map = new kakao.maps.Map(mapContainer, options);

        const markerPositions = [new kakao.maps.LatLng(35.841486, 128.760341)];

        markerPositions.forEach((position) => {
          const marker = new kakao.maps.Marker({
            position: position,
          });
          marker.setMap(map);
        });
      });
    });
  }, []);
  const addressRef = useRef(null);

  const handleCopyClick = () => {
    if (addressRef.current) {
      const range = document.createRange();
      range.selectNode(addressRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);

      document.execCommand("copy");

      window.getSelection().removeAllRanges();
    }
  };

  return (
    <Wrapper style={{ display: "flex", justifyContent: "center" }}>
      <MapContainer>
        <h1>찾아오시는길</h1>

        <div id="map" className="map" />
      </MapContainer>

      <TextWrapper>
        <h1 style={{ color: "#1e0d8d", fontSize: 22 }}>URconnection</h1>
        <h1 style={{ color: "#1e0d8d", fontSize: 22 }}>
          유알커넥션은 항상 열려있습니다.
        </h1>
        <h1>South Korea</h1>

        <div style={{ display: "flex" }}>
          <p ref={addressRef}>
            주소:경북 경산시 압량읍 대학로367 SG트윈타워, 401호
          </p>
          <ContentCopyIcon
            onClick={handleCopyClick}
            style={{ cursor: "pointer" }}
          />
        </div>
        <p>전화: 053-813-1027</p>
        <p>팩스: 053-813-1028</p>
      </TextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 50px;
  margin: 50px 0;
  width: 100%;
  .map {
    flex: 1;
    height: 400px;
    /* width: 400px;
    height: 400px; */
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 400px;
  }
`;

const MapContainer = styled.div`
  flex: 1;
  .map {
    flex: 1;
    height: 400px;
    /* width: 400px;
    height: 400px; */
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    h1 {
      margin: 0;
      padding: 0;
    }
    .map {
      display: none;
    }
  }
`;
const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-size: large;
    margin-left: 20px;
  }
  h1,
  h3 {
    @media only screen and (max-width: 900px) {
      text-align: center;
    }
  }
`;

export default KakaoMap;
