import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Navbar from "./components/navbar";
import HomeComponent from "./components/pages/home";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import FooterComponent from "./components/pages/footer";
import CompanyRoot from "./components/pages/company";
import ServiceComponent from "./components/pages/service";
import StudyServiceComponent from "./components/pages/service";
import WorkServiceComponent from "./components/pages/workService";
import CorporateServiceComponent from "./components/pages/corporateService";
import RegionalServiceComponent from "./components/pages/regionalService";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/company-info" element={<CompanyRoot />} />
        <Route path="/work-service" element={<WorkServiceComponent />} />
        <Route path="/study-service" element={<StudyServiceComponent />} />
        <Route
          path="/corporate-service"
          element={<CorporateServiceComponent />}
        />
        <Route
          path="/regional-service"
          element={<RegionalServiceComponent />}
        />
      </Routes>
      <FooterComponent />
    </BrowserRouter>
  </React.StrictMode>
);
