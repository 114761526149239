import React from "react";
import { ConLeft, ConRight, Container, NavCon } from "./style";
import logo from "../../assets/logo.png";

const FooterComponent = () => {
  return (
    <>
      <Container>
        <ConLeft>
          <img src={logo} alt="logo" width={150} />
          <div style={{ marginTop: -50 }}>
            <p>대표자 : 주수인</p>
            <p>
              주소 : 경북 경산시 압량읍 대학로367 <br />
              SG트윈타워, 401호
            </p>
            <p>전화번호 : 053-813-1027</p>
            <p>통신판매업 번호 : 2024-경북경산-0042</p>
            <p>직업소개소 번호: 2023-5130180-14-5-00014</p>
          </div>
        </ConLeft>
        <ConRight>
          <div>
            <NavCon to="/company-products">
              <p> 회사소개</p>
            </NavCon>
            <div style={{ marginTop: "25px" }}>
              <NavCon to="/company-products">
                <p>회사 조직도</p>
              </NavCon>
              <NavCon to="/company-products">
                <p>찾아오시는 길</p>
              </NavCon>
            </div>
          </div>
          <div>
            <NavCon to="/company-products">
              <p> 외국인 생애 지원 서비스</p>
            </NavCon>
            <div style={{ marginTop: "25px" }}>
              <NavCon to="/company-products">
                <p>취업지원</p>
              </NavCon>
              <NavCon to="/company-products">
                <p>경력개발</p>
              </NavCon>
              <NavCon to="/company-products">
                <p>비자전환</p>
              </NavCon>
            </div>
          </div>
          <div>
            <NavCon to="/company-products">
              <p> 유학생 지원 서비스</p>
            </NavCon>
            <div style={{ marginTop: "25px" }}>
              <NavCon to="/company-products">
                <p>어학연수</p>
              </NavCon>
              <NavCon to="/company-products">
                <p>대학입학</p>
              </NavCon>
              <NavCon to="/company-products">
                <p>School to Work</p>
              </NavCon>
            </div>
          </div>
          <div>
            <NavCon to="/company-products">
              <p> 기업맞춤형 외국인고용 서비스</p>
            </NavCon>
            <div style={{ marginTop: "25px" }}>
              <NavCon to="/company-products">
                <p>취업비자 고용 컨설팅</p>
              </NavCon>
              <NavCon to="/company-products">
                <p>채용의뢰</p>
              </NavCon>
            </div>
          </div>
          <div>
            <NavCon to="/company-products">
              <p> 지역혁신 연구소</p>
            </NavCon>
            <div style={{ marginTop: "25px" }}>
              <NavCon to="/company-products">
                <p>정책 연구</p>
              </NavCon>
              <NavCon to="/company-products">
                <p>지역혁신 컨설팅</p>
              </NavCon>
              <NavCon to="/company-products">
                <p>교육훈련</p>
              </NavCon>
            </div>
          </div>
        </ConRight>
      </Container>
    </>
  );
};

export default FooterComponent;
