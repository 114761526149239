import React, { useState } from "react";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles.css";
import { Link } from "react-router-dom";
import { NavCon } from "../style";

const MOBILE_NAV_ITEMS = [
  {
    id: 0,
    navTitle: "회사소개",
  },
  {
    id: 1,
    navTitle: "외국인 생애 지원 서비스",
  },
  {
    id: 2,
    navTitle: "유학생 지원 서비스",
  },
  {
    id: 3,
    navTitle: "기업맞춤형 외국인고용 서비스",
  },
  {
    id: 4,
    navTitle: "지역혁신 연구소",
  },
];

const MobileNav = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const hideNavItemsVariant = {
    opened: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 1,
      y: "0%",
      transition: {
        delay: 1.1,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const mobileMenuVariant = {
    opened: {
      y: "0%",
      transition: {
        delay: 0.15,
        duration: 1.1,
        ease: [0.74, 0, 0.19, 1.02],
      },
    },
    closed: {
      y: "-100%",
      transition: {
        delay: 0.35,
        duration: 0.63,
        ease: [0.74, 0, 0.19, 1.02],
      },
    },
  };

  const fadeInVariant = {
    opened: {
      opacity: 1,
      transition: {
        delay: 1.2,
      },
    },
    closed: { opacity: 0 },
  };

  const ulVariant = {
    opened: {
      transition: {
        delayChildren: 1,
        staggerChildren: 0.18,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.06,
        staggerDirection: -1,
      },
    },
  };

  const liVariant = {
    opened: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.65,
        ease: "easeOut",
      },
    },
    closed: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.25,
        ease: "easeInOut",
      },
    },
  };

  return (
    <main className="container">
      <motion.nav
        initial="closed"
        animate={mobileNavOpen ? "opened" : "closed"}
      >
        {/* <div className="logo-container">
          <motion.h1 variants={hideNavItemsVariant}>Design Agency</motion.h1>
        </div> */}
        <div className="menu-container">
          <motion.div
            variants={hideNavItemsVariant}
            onClick={() => setMobileNavOpen(true)}
          >
            <MenuIcon />
          </motion.div>
        </div>
        <motion.div variants={mobileMenuVariant} className="mobile-menu">
          <motion.button
            variants={fadeInVariant}
            onClick={() => setMobileNavOpen(false)}
          >
            Close
          </motion.button>
          <motion.ul variants={ulVariant}>
            <motion.li
              whileTap={{ scale: 0.95 }}
              onClick={() => setMobileNavOpen(false)}
            >
              <NavCon to="/company-info">
                <motion.div variants={liVariant} style={{ fontSize: 20 }}>
                  회사소개
                </motion.div>
              </NavCon>
            </motion.li>
            <motion.li
              whileTap={{ scale: 0.95 }}
              onClick={() => setMobileNavOpen(false)}
            >
              <NavCon to="/work-service">
                <motion.div variants={liVariant} style={{ fontSize: 20 }}>
                  외국인 생애 지원 서비스
                </motion.div>
              </NavCon>
            </motion.li>
            <motion.li
              whileTap={{ scale: 0.95 }}
              onClick={() => setMobileNavOpen(false)}
            >
              <NavCon to="/study-service">
                <motion.div variants={liVariant} style={{ fontSize: 20 }}>
                  유학생 지원 서비스
                </motion.div>
              </NavCon>
            </motion.li>
            <motion.li
              whileTap={{ scale: 0.95 }}
              onClick={() => setMobileNavOpen(false)}
            >
              <NavCon to="/corporate-service">
                <motion.div variants={liVariant} style={{ fontSize: 20 }}>
                  기업맞춤형 외국인고용 서비스
                </motion.div>
              </NavCon>
            </motion.li>
            <motion.li
              whileTap={{ scale: 0.95 }}
              onClick={() => setMobileNavOpen(false)}
            >
              <NavCon to="/regional-service">
                <motion.div variants={liVariant} style={{ fontSize: 20 }}>
                  지역혁신 연구소
                </motion.div>
              </NavCon>
            </motion.li>
            <motion.li
              whileTap={{ scale: 0.95 }}
              onClick={() => setMobileNavOpen(false)}
            >
              <NavCon to="/contact-us">
                <motion.div variants={liVariant} style={{ fontSize: 20 }}>
                  문의하기
                </motion.div>
              </NavCon>
            </motion.li>
          </motion.ul>
        </motion.div>
      </motion.nav>
    </main>
  );
};

export default MobileNav;
