import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 20;
  transition: opacity 0.5s ease, transform 0.5s ease; /* Transition effect */

  &:hover {
    display: flex;
    opacity: 1;
    transform: translateY(0);
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const NavCon = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const ConRight = styled.div`
  flex: 6;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media only screen and (max-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: end;
  }
`;

export const ConContactItem = styled.div`
  border: 2px solid #61dafb;
  padding: 5px 20px;
  &:hover {
    background-color: #61dafb;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const ConRightItem = styled.div`
  cursor: pointer;
  padding: 20px;
  font-size: 20px;
  &:hover {
    div {
      display: block;
    }
  }

  div {
    display: none; /* Initially hide the dropdown */
    position: absolute;
    top: 100%;
    margin-top: -10px;
    margin-left: -20px;
    min-width: 200px;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;

    :hover {
      padding: 10px;
      text-decoration: underline;
      color: rgba(111, 163, 144, 255);
    }
    p {
      font-size: 20px;
      padding: 10px;
      border-bottom: 1px solid #f5f5f5;
    }
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 900px) {
    display: flex;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
