import React from "react";
import { motion } from "framer-motion";
import "../../../../index.css";

function Card({ index }) {
  return (
    <motion.div
      className="card-detail"
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 1, // Animation duration
        },
      }}
      viewport={{ once: true }}
    >
      <p style={{ color: "blue", fontSize: "40px" }}>
        기업 맞룸형 외국인 공용 서비스
      </p>
      <p style={{ color: "black", fontSize: "22px", marginLeft: "30px" }}>
        인력부족을 겪고 잇는 기업에게 기업수요에 적합한 외국인 채용서비스를
        수행합니다.
      </p>
    </motion.div>
  );
}

export default Card;
