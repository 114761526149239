import React from "react";
import App from "./App";

const DownloadButton = () => {
  return (
    <div>
      <App />
    </div>
  );
};

export default DownloadButton;
