import React, { useEffect, useState } from "react";
import { Container } from "../company/style";
import { Content, StyledWallpaper, Wrapper } from "../home";
import DownloadButton from "../../constant/buttons/download";
import DarkGlobe from "../../constant/darkGlobe";
import bg1 from "../../assets/service/bg.png";
import bg2 from "../../assets/service/bg2.png";
import ServiceContainer from "./containers";
const backgrounds = [bg1, bg2];
const StudyServiceComponent = () => {
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeout(() => {
        setCurrentBackgroundIndex((prevIndex) =>
          prevIndex === backgrounds.length - 1 ? 0 : prevIndex + 1
        );
      });
    }, 5000); // Interval for changing backgrounds every 10 seconds

    return () => clearInterval(intervalId);
  }, []);
  return (
    <Container>
      <StyledWallpaper
        style={{
          backgroundImage: `url(${backgrounds[currentBackgroundIndex]})`,
          transition:
            "transform 5s ease-in-out, background-image 2s ease-in-out",
        }}
      >
        <Content>
          <div>
            <p style={{ fontSize: 60 }}>
              유학생 지원 서비스 <br />
              URconnection
            </p>
            <p style={{ marginTop: "30px", fontSize: 25 }}>
              우리는 외국인들에게 한국 생화를 소개합니다.
            </p>
          </div>
          <DownloadButton />
        </Content>
        <Wrapper>
          <DarkGlobe />
        </Wrapper>
      </StyledWallpaper>
      <ServiceContainer />
    </Container>
  );
};

export default StudyServiceComponent;
