import React from "react";
import { motion } from "framer-motion";
import "../../../../index.css";
import { TextWrapper } from "../../service/containers/cardWrapper";

function Card({ index }) {
  return (
    <motion.div
      className="card-detail"
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 1, // Animation duration
        },
      }}
      viewport={{ once: true }}
    >
      <TextWrapper>
        <h2>
          인구 감소 및 지방소멸에 대용하여 지역발전 및 고령회시대에 기여하는
          것을 목표로 최고의
          <br />
          서비스 제공과 츼상의 고객만족을 위해 나아가고 있습니다.
        </h2>
      </TextWrapper>
    </motion.div>
  );
}

export default Card;
