import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../../../../index.css";
import family from "../../../assets/company/document.png";
import idcard from "../../../assets/company/laptop.png";
import hirring from "../../../assets/company/briefcase.png";
import consulting from "../../../assets/company/handshake.png";
import visaService from "../../../assets/company/office-supplies.png";
import support from "../../../assets/company/office.png";
import styled from "styled-components";
const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.4,
    },
  },
};

const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8, // Adjust the duration to make the animation faster
    },
  },
};

const CardOne = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>01 </h1>
        </div>
        <div className="img-wrapper">
          <img src={hirring} alt="support-img" width={"50%"} />
        </div>
        <div style={{ padding: "5px 20px " }}>
          <p>
            어학연수를 회망하는 유학생에게 체계적 커리큘럼을 가진
            한국어학연수기광을 안내
          </p>
        </div>
      </div>
    </div>
  );
};
const CardTwo = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>02 </h1>
        </div>
        <div className="img-wrapper">
          <img src={idcard} alt="support-img" width={"50%"} />
        </div>
        <div style={{ padding: "5px 20px " }}>
          <p>
            대학진학을 앞둔 유학생에게 직업 생애와 연계하여 대학소개, 전공 선택
            들의 유학생 맞춤형 진로상담을 제공
          </p>
        </div>
      </div>
    </div>
  );
};

const CardThird = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>03 </h1>
        </div>
        <div className="img-wrapper">
          <img src={family} alt="support-img" width={"50%"} />
        </div>
        <div style={{ padding: "5px 20px " }}>
          <p>
            유학생(졸업 후 구직비자 소지자 포함)에게 진로에 대한 컨설팅 및
            한국기업으로의 취업컨설팅을 수행
          </p>
        </div>
      </div>
    </div>
  );
};

const CardFour = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>04 </h1>
        </div>
        <div className="img-wrapper">
          <img src={consulting} alt="support-img" width={"50%"} />
        </div>
        <div style={{ padding: "5px 20px " }}>
          <p>자격요건을 갖춘 구직자에게 맞춤형 취업 알선 서비스를 진행</p>
        </div>
      </div>
    </div>
  );
};

const CardFive = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>05 </h1>
        </div>
        <div className="img-wrapper">
          <img src={visaService} alt="support-img" width={"50%"} />
        </div>
        <div style={{ padding: "5px 20px " }}>
          <p>구지에 필요한 서류대행, 취업 시 비자취득 서비스 제공</p>
        </div>
      </div>
    </div>
  );
};
const CardSix = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>06 </h1>
        </div>
        <div className="img-wrapper">
          <img src={support} alt="support-img" width={"50%"} />
        </div>
        <div style={{ padding: "5px 20px " }}>
          <p>직장 및 지역사회에 정착할 수 있도록 정착지원서비스 제공</p>
        </div>
      </div>
    </div>
  );
};

const CardWrapper = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div>
      <div style={{ padding: "0 15%" }} ref={ref}>
        <TextWrapper>
          <h1>동행하는 유알커넥션</h1>
        </TextWrapper>
        <motion.ul
          className="company-card-maincontainer2"
          variants={container}
          initial="hidden"
          animate={controls}
        >
          {[
            <CardOne />,
            <CardTwo />,
            <CardThird />,
            <CardFour />,
            <CardFive />,
            <CardSix />,
          ].map((card, index) => (
            <motion.li key={index} variants={item}>
              {card}
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </div>
  );
};

export const TextWrapper = styled.div`
  font-size: 60px;
  font-weight: 600;
  p {
    margin-left: 20px;
  }
  .description {
    font-size: 25px;
  }
  h2 {
    color: black;
    font-size: 22px;
    margin-left: 30px;
    text-align: center;
  }
  @media only screen and (max-width: 700px) {
    h2 {
      color: black;
      font-size: 15px;
      margin-left: 0px;
      text-align: center;
    }
    .description {
      font-size: 15px;
      text-align: center;
    }
    p {
      font-size: 25px;
      text-align: center;
      font-weight: 600;
    }
    h1 {
      font-size: 20px;
      text-align: center;

      margin-bottom: -100px;
    }
  }
`;

export default CardWrapper;
