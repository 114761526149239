import React, { useState, useEffect } from "react";
import Globe from "../../constant/globe";
import styled from "styled-components";
import bg1 from "../../assets/wallpaper/Recruitment.jpg";
import bg2 from "../../assets/wallpaper/good-looks-like-min.png";
import HomeDetail from "./containers";
import AnnounceComponent from "./containers/announce";
import ConnectionButton from "../../constant/buttons/download/connection";

const backgrounds = [bg1, bg2];

const HomeComponent = () => {
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeout(() => {
        setCurrentBackgroundIndex((prevIndex) =>
          prevIndex === backgrounds.length - 1 ? 0 : prevIndex + 1
        );
      });
    }, 5000); // Interval for changing backgrounds every 10 seconds

    return () => clearInterval(intervalId);
  }, []);
  return (
    <Container>
      <StyledWallpaper
        $HomeNav
        style={{
          backgroundImage: `url(${backgrounds[currentBackgroundIndex]})`,
          transition:
            "transform 5s ease-in-out, background-image 2s ease-in-out",
        }}
      >
        <Content>
          <div>
            <p style={{ fontSize: 60 }}>
              외국인과 기업에 <br /> 길을 알려주다
            </p>
            <p style={{ marginTop: "30px", fontSize: 25 }}>
              복잡한 비자요건은 그만 <br /> 맞춤형 외국인 고용서비스로 행복을
              채워가요
            </p>
          </div>
          <a
            href="https://www.connectsoon.co.kr/"
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <ConnectionButton />
          </a>
        </Content>
        <Wrapper>
          <Globe />
        </Wrapper>
      </StyledWallpaper>
      <HomeDetail />
      <AnnounceComponent />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledWallpaper = styled.div`
  height: 800px;
  background-position: ${(props) => (props.$HomeNav ? "top" : "center")};
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.div`
  position: absolute;
  flex: 1;
  /* transform: translate(-50%, -50%); */
  color: white;
  display: flex;
  align-items: start;
  flex-direction: column;
  padding-left: 5%;
  width: 100%;
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 800px;
  background-color: rgba(0, 0, 0, 0.337);
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export default HomeComponent;
