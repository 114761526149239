import React from "react";
import Card from "./detailPage";
import { Container } from "../../company/containers";
import CardWrapper from "./cardWrapper";

const ServiceContainer = () => {
  return (
    <div>
      <div className="App">
        <Container>
          <Card />
        </Container>
      </div>
      <CardWrapper />
    </div>
  );
};

export default ServiceContainer;
