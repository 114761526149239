import React from "react";
import DownloadButton from "../../../constant/buttons/download";

const AnnounceComponent = () => {
  return (
    <>
      <div style={{ border: "1px solid black", height: 600, padding: 50 }}>
        <h1>공지사항 : 업로드 게시판</h1>
      </div>
      <div style={{ position: "fixed", bottom: 50, right: 50 }}>
        <DownloadButton />
      </div>
    </>
  );
};

export default AnnounceComponent;
