import React from "react";
import { motion } from "framer-motion";
import "../../../../index.css";

function Card({ index }) {
  return (
    <motion.div
      className="card-detail"
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 1, // Animation duration
        },
      }}
      viewport={{ once: true }}
    >
      <p style={{ color: "blue", fontSize: "40px" }}>유학생 지원서비스</p>
      <p style={{ color: "black", fontSize: "22px", marginLeft: "30px" }}>
        한국의 대학에 어학연수나 유학을 회망하는 <br />
        유학생에게 동반자가 되어 함께 합니다.
      </p>
    </motion.div>
  );
}

export default Card;
