import React, { useEffect, useState } from "react";
import { Container } from "./style";
import { Content, StyledWallpaper, Wrapper } from "../home";

import bg1 from "../../assets/wallpaper/aboutBg.jpg";
import bg2 from "../../assets/wallpaper/companyBg.jpeg";
import DownloadButton from "../../constant/buttons/download";
import DarkGlobe from "../../constant/darkGlobe";
import CompanyDetail from "./containers";
import { TextWrapper } from "../service/containers/cardWrapper";

const backgrounds = [bg1, bg2];

const CompanyRoot = () => {
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeout(() => {
        setCurrentBackgroundIndex((prevIndex) =>
          prevIndex === backgrounds.length - 1 ? 0 : prevIndex + 1
        );
      });
    }, 5000); // Interval for changing backgrounds every 10 seconds

    return () => clearInterval(intervalId);
  }, []);
  return (
    <Container>
      <StyledWallpaper
        style={{
          backgroundImage: `url(${backgrounds[currentBackgroundIndex]})`,
          transition:
            "transform 5s ease-in-out, background-image 2s ease-in-out",
        }}
      >
        <Content>
          <div>
            <TextWrapper>
              <p>
                화사 소개 <br />
                URconnection
              </p>
              <p style={{ marginTop: "30px" }} className="description">
                도시(Urban)와 지방(Rural)를 <br /> 연결한다는 의미를 갖고
                있습니다
              </p>
            </TextWrapper>
          </div>
          <DownloadButton />
        </Content>
        <Wrapper>
          <DarkGlobe />
        </Wrapper>
      </StyledWallpaper>
      <CompanyDetail />
    </Container>
  );
};

export default CompanyRoot;
