import styled from "styled-components";
import "../../../../index.css";

import Card from "./detailPage";
import CardWrapper from "./cardWrapper";

export default function HomeDetail() {
  return (
    <div>
      <div className="App">
        <Container>
          <Card />
        </Container>
      </div>
      <CardWrapper />
      <Wrapper>
        <h1>
          비즈니스도 <br /> 유알커넥션과 함께
        </h1>
        <div>
          <p>프로모션제휴, 업무협약 등 외국인 관리 및 지원까지 함께해요</p>
          <div style={{ display: "flex", padding: 0 }}>
            <Button variant={{ color: "#fff" }} style={{ color: "#fff" }}>
              B2B 제휴문의
            </Button>
            <Button variant={{ color: "#fff" }} style={{ color: "#fff" }}>
              문의하기
            </Button>
          </div>
        </div>
      </Wrapper>
    </div>
  );
}
const Container = styled.div`
  background-color: rgb(233, 247, 248);
  /* padding: 3% 15%; */
`;

const Wrapper = styled.div`
  background-color: rgb(66, 72, 76);
  color: white;
  padding: 5%;
  display: flex;
  h1 {
    flex: 1;
    align-items: center;
  }
  div {
    flex: 2;
    padding: 20px;
  }
  p {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const Button = styled.button`
  border: 1px solid gray;
  background-color: transparent;
  font-size: 16px;
  margin: 0;
  margin-right: 20px;
`;
