import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: #dddfe3;
  padding: 80px 0;

  p {
    margin-top: 5px;
    font-weight: 400;
  }
  img {
    margin-bottom: 40px;
  }
`;

export const ConLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  @media only screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    p {
      text-align: center;
      margin-top: 20px;
    }
  }
`;
export const ConRight = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-evenly;
  p {
    cursor: pointer;
    margin-top: 10px;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const NavCon = styled(Link)`
  text-decoration: none;
  color: black;
  :hover {
    text-decoration: underline;
    color: rgba(111, 163, 144, 255);
  }
`;
