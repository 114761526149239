import React, { useEffect, useState } from "react";
import { Container, MainRigtWrapper, MainWrapper, Wrapper } from "./style";
import "../../../../index.css";

import img from "../../../assets/wallpaper/helloBg.gif";
import animationCard from "../../../assets/company/AnimationCard.gif";
import animationCard1 from "../../../assets/company/AnimationCard3.gif";
import animationCard2 from "../../../assets/company/AnimationCard2.gif";
import { TextWrapper } from "../../service/containers/cardWrapper";
const milestones = [
  {
    year: 2023,
    month: "05월",
    description: "창업중심대학 예비창업패키지 선정",
  },
  {
    year: 2023,
    month: "07월",
    description: "유알커넥션 창업",
  },
  {
    year: 2023,
    month: "07월",
    description: "혁신창업스쿨 2단계 선정",
  },
  {
    year: 2023,
    month: "08월",
    description: "경상북도, 한국이민학회 ‘유학생 취업지원‘ 운영",
  },
  {
    year: 2023,
    month: "11월",
    description:
      "대구청년창업사관학교 x 대구대학교창업지원단 스타트업 IR피칭 네트워킹 데이 우수상 수상",
  },
  {
    year: 2023,
    month: "12월",
    description: "위덕대학교 외국인 유학생 취업교육설명회",
  },
  {
    year: 2023,
    month: "12월",
    description: "구미대학교 외국인 유학생 취업교육설명회",
  },
  {
    year: 2023,
    month: "12월",
    description: "대구가톨릭대학교 외국인 유학생 취업교육설명회",
  },
  {
    year: 2023,
    month: "12월",
    description: "안동대학교 외국인 유학생 취업교육설명회",
  },
  {
    year: 2023,
    month: "12월",
    description: "대경대학교 외국인 유학생 취업교육설명회",
  },
  {
    year: 2024,
    month: "01월",
    description: "금오공과대학교 외국인 유학생 취업교육설명회",
  },
  {
    year: 2024,
    month: "01월",
    description: "창업중심대학 예비창업패키지 성과평가 : 최우수",
  },
];

const BossComponent = () => {
  const [salesAmount, setSalesAmount] = useState(0);
  const [salesCountAmount, setSalesCountAmount] = useState(0);
  useEffect(() => {
    const finalSalesAmount = 1200;
    const increment = finalSalesAmount / 100;

    let counter = 0;
    const timer = setInterval(() => {
      if (counter >= finalSalesAmount) {
        clearInterval(timer);
      } else {
        setSalesAmount(counter);
        counter += increment;
      }
    }, 60);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const finalSalesAmount = 1818;
    const increment = finalSalesAmount / 100;

    let counter = 0;
    const timer = setInterval(() => {
      if (counter >= finalSalesAmount) {
        clearInterval(timer);
      } else {
        setSalesCountAmount(counter);
        counter += increment;
      }
    }, 60);
    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <Container>
        <div>
          <h1 style={{ marginBottom: 20 }}>회사 조직도</h1>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MainWrapper>
              <Wrapper $extraHeight>
                <div style={{ padding: 30 }}>
                  <TextWrapper>
                    <p
                      style={{
                        fontSize: 30,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      2023부터 외국인의 취업과 <br /> 대학 진학을 도와드립니다.
                    </p>
                  </TextWrapper>
                  <img src={img} alt="img-logo" />
                </div>
              </Wrapper>
              <MainRigtWrapper>
                <Wrapper $color>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ fontSize: 20, fontWeight: 500 }}>
                        외국인 회원 컨설팅 횟수 :
                      </p>
                      <p style={{ fontSize: 40, fontWeight: 700 }}>
                        {salesCountAmount.toFixed(0)}회
                      </p>
                    </div>
                    <img
                      src={animationCard}
                      alt="animation-card"
                      style={{ width: "160px" }}
                    />
                  </div>
                </Wrapper>
                <Wrapper $color>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ fontSize: 20, fontWeight: 500 }}>
                        외국인 고용 관련 기업 컨설팅 :
                      </p>
                      <p style={{ fontSize: 40, fontWeight: 700 }}>
                        {salesAmount.toFixed(0)}회
                      </p>
                    </div>

                    <img
                      src={animationCard1}
                      alt="animation-card"
                      style={{ width: "160px" }}
                    />
                  </div>
                </Wrapper>
                <Wrapper $color>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: 20, fontWeight: 500 }}>
                        실시간 자체적 산업 <br /> 및 지역 분석
                      </p>
                    </div>
                    <img
                      src={animationCard2}
                      alt="animation-card"
                      style={{ width: "160px" }}
                    />
                  </div>
                </Wrapper>
              </MainRigtWrapper>
            </MainWrapper>

            <Wrapper $biggerPadding>
              {milestones.map((milestone, index) => (
                <div className="event" key={index}>
                  <div className="event-content">
                    <div className="year">{milestone.year}</div>
                    <li
                      style={{
                        fontSize: 30,
                        width: 30,

                        color: "rgb(0, 174, 194) ",
                      }}
                    />
                    <div className="month">{milestone.month}</div>
                    <div className="description">{milestone.description}</div>
                  </div>
                </div>
              ))}
            </Wrapper>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BossComponent;
