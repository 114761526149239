import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../../../../index.css";
import family from "../../../assets/company/family.png";
import idcard from "../../../assets/company/id.png";
import hirring from "../../../assets/company/hiring.png";
import consulting from "../../../assets/company/handshake.png";
const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.4,
    },
  },
};

const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8, // Adjust the duration to make the animation faster
    },
  },
};

const CardOne = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>01 </h1>
          <p>외국인 구직자(유학생 표함)</p>
        </div>
        <div className="img-wrapper">
          <img
            src={hirring}
            alt="support-img"
            width={"50%"}
            style={{ margin: 20 }}
          />
        </div>
        <div>
          <p>취업 알선</p>
        </div>
      </div>
    </div>
  );
};
const CardTwo = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>02 </h1>
          <p>사업주 및 외국인</p>
        </div>
        <div className="img-wrapper">
          <img
            src={idcard}
            alt="support-img"
            width={"50%"}
            style={{ margin: 20 }}
          />
        </div>
        <div>
          <p>취업비자 발급 지원</p>
        </div>
      </div>
    </div>
  );
};

const CardThird = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>03 </h1>
          <p>외국인 근로자(가족)</p>
        </div>
        <div className="img-wrapper">
          <img
            src={family}
            alt="support-img"
            width={"50%"}
            style={{ margin: 20 }}
          />
        </div>
        <div>
          <p>한국사회 적응 및 경력개발 지원</p>
        </div>
      </div>
    </div>
  );
};

const CardFour = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>04 </h1>
          <p>지방자치단체 일자리 창출</p>
        </div>
        <div className="img-wrapper">
          <img
            src={consulting}
            alt="support-img"
            width={"50%"}
            style={{ margin: 20 }}
          />
        </div>
        <div>
          <p>전략 수립 및 컨설팅</p>
        </div>
      </div>
    </div>
  );
};

const CardWrapper = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div>
      <div style={{ padding: "0 15%" }} ref={ref}>
        <h1>동행하는 유알커넥션</h1>
        <motion.ul
          className="company-card-maincontainer"
          variants={container}
          initial="hidden"
          animate={controls}
        >
          {[<CardOne />, <CardTwo />, <CardThird />, <CardFour />].map(
            (card, index) => (
              <motion.li key={index} variants={item}>
                {card}
              </motion.li>
            )
          )}
        </motion.ul>
      </div>
    </div>
  );
};

export default CardWrapper;
