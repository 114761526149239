import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  /* padding-top: 40px; */
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const Wrapper = styled.div`
  margin-top: 50px;
`;
