import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../../../../index.css";

import support from "../../../assets/icons/social-media.png";
import studentSupport from "../../../assets/icons/digital-marketing.png";
import clinic from "../../../assets/company/handshake.png";
import programmer from "../../../assets/icons/digital-marketing3.png";
import analytic from "../../../assets/icons/data-analysis.png";
import education from "../../../assets/icons/feedback.png";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.4,
    },
  },
};

const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8, // Adjust the duration to make the animation faster
    },
  },
};

const CardOne = () => {
  return (
    <div className="card-container">
      <div className="card-wrap">
        <div>
          <h1>외국인 생애지원 서비스 : </h1>
          <p> 채용 및 경력개발까지 지원합니다.</p>
          <div className="btn-wrapper">
            <button>+</button>
          </div>
        </div>
        <div className="img-wrapper">
          <img src={support} alt="support-img" width={"50%"} />
        </div>
      </div>
    </div>
  );
};
const CardTwo = () => {
  return (
    <div className="card-container">
      <div className="card-wrap">
        <div>
          <h1> 유학생 지원서비스 :</h1> <p>입학부터 취업까지 지원합니다.</p>
          <div className="btn-wrapper">
            <button>+</button>
          </div>
        </div>
        <div className="img-wrapper">
          <img src={studentSupport} alt="support-img" width={"50%"} />
        </div>
      </div>
    </div>
  );
};

const CardThird = () => {
  return (
    <div className="card-container">
      <div className="card-wrap">
        <div>
          <h1> 직업훈련서비스 :</h1>
          <p>지역산업에 맞는 숙련인력을 육성합니다.</p>
          <div className="btn-wrapper">
            <button>+</button>
          </div>
        </div>
        <div className="img-wrapper">
          <img src={clinic} alt="support-img" width={"50%"} />
        </div>
      </div>
    </div>
  );
};

const CardFour = () => {
  return (
    <div className="card-container">
      <div className="card-wrap">
        <div>
          <h1>기업맞춤형 외국인 고용서비스: </h1>
          <p> 외국인 고용관리의 어려움을 해결해줍니다</p>
          <div className="btn-wrapper">
            <button>+</button>
          </div>
        </div>
        <div className="img-wrapper">
          <img src={programmer} alt="support-img" width={"50%"} />
        </div>
      </div>
    </div>
  );
};
const CardFive = () => {
  return (
    <div className="card-container">
      <div className="card-wrap">
        <div>
          <h1> 실태조사 및 연구 : </h1>
          <p>증거기반의 연구를 통한 심층조사를 진행합니다.</p>
          <div className="btn-wrapper">
            <button>+</button>
          </div>
        </div>
        <div className="img-wrapper">
          <img src={analytic} alt="support-img" width={"50%"} />
        </div>
      </div>
    </div>
  );
};
const CardSix = () => {
  return (
    <div className="card-container">
      <div className="card-wrap">
        <div>
          <h1> 지역혁신 연구소 :</h1>
          <p>저출산 고령화시대 지역혁신의 길잡이가 되겠습니다.</p>
          <div className="btn-wrapper">
            <button>+</button>
          </div>
        </div>
        <div className="img-wrapper">
          <img src={education} alt="support-img" width={"50%"} />
        </div>
      </div>
    </div>
  );
};
const CardWrapper = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div>
      <img
        src="https://prezlab.com/wp-content/uploads/2022/12/Linked-in-Top_Banner.gif"
        alt="card"
        width={"100%"}
      />

      <div style={{ padding: "0 15%" }} ref={ref}>
        <h1>동행하는 유알커넥션</h1>
        <motion.ul
          className="card-maincontainer"
          variants={container}
          initial="hidden"
          animate={controls}
        >
          {[
            <CardOne />,
            <CardTwo />,
            <CardThird />,
            <CardFour />,
            <CardFive />,
            <CardSix />,
          ].map((card, index) => (
            <motion.li key={index} variants={item}>
              {card}
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </div>
  );
};

export default CardWrapper;
