import styled from "styled-components";
export const Container = styled.div`
  padding: 100px 0;
  background-color: ${(props) =>
    props.$member ? "white" : "rgb(233, 247, 248)"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 970px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    padding: 20px;
  }
  /* width: 100%; */
`;

export const MainWrapper = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 970px) {
    display: grid;
  }
`;
export const MainRigtWrapper = styled.div`
  display: grid;
  gap: 15px;
  @media (max-width: 970px) {
    display: grid;
  }
`;

export const Wrapper = styled.div`
  margin-top: ${(props) => (props.$biggerPadding ? "20px" : "0")};
  background-color: ${(props) => (props.$color ? "rgb(0, 189, 214)" : "white")};
  border-radius: 20px;
  padding: ${(props) => (props.$biggerPadding ? "50px 35px" : "")};
  height: ${(props) => (props.$extraHeight ? "" : "")};
  /* height: 420px; */
  /* padding: 30px; */
  /* margin: 10px; */
  width: ${(props) => (props.$biggerPadding ? "" : "450px")};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.$biggerPadding ? "" : "center")};

  p {
    font-weight: 500;
    font-size: 22px;
  }
  div p {
    /* margin-right: 20px; */
  }
  img {
    width: 350px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: -5px;
    @media (max-width: 970px) {
      width: 100%;
    }
  }
  @media (max-width: 970px) {
    width: 100%;
  }
`;
export const ImgWapper = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-areas: "a a";
  gap: 20px;
  @media (max-width: 970px) {
    display: grid;
    grid-template-areas: "a";
  }
`;

export const OtherWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`;

export const PartnerWrapper = styled.div`
  padding: 50px 0;

  background-color: rgb(246, 240, 230);
`;
