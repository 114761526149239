import React from "react";
import { motion } from "framer-motion";
import "../../../../index.css";

function Card({ index }) {
  return (
    <motion.div
      className="card-detail"
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 1, // Animation duration
        },
      }}
      viewport={{ once: true }}
    >
      <p style={{ color: "blue", fontSize: "40px" }}>외국인 생애 지원서비스</p>
      <p style={{ color: "black", fontSize: "22px", marginLeft: "30px" }}>
        한국기업에 취업하기를 희망하는 해외와 국내 거주 외국인에게 <br />
        진로상담, 취업알선,직업훈련 등 구직자 맞춤형 취업지원서비스를
        제공합니다.
      </p>
    </motion.div>
  );
}

export default Card;
