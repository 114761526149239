import styled from "styled-components";
import "../../../../index.css";

import Card from "./detailPage";
import CardWrapper from "./cardWrapper";
import BossComponent from "../details/boss";
import graph from "../../../assets/company/ceo.png";
import AddressComponent from "./address";

export default function CompanyDetail() {
  return (
    <div>
      <div className="App">
        <Container>
          <Card />
        </Container>
      </div>
      <CardWrapper />
      <BossComponent />
      <div style={{ display: "flex", justifyContent: "center", padding: "2%" }}>
        <img src={graph} alt="ceo-graph" width={"70%"} />
      </div>
      <div style={{ display: "flex" }}>
        <AddressComponent />
      </div>
    </div>
  );
}
export const Container = styled.div`
  background-color: rgb(233, 247, 248);
  padding: 0 5%;
  border: 1px solid black;
  @media only screen and (max-width: 700px) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  background-color: rgb(66, 72, 76);
  color: white;
  padding: 5%;
  display: flex;
  h1 {
    flex: 1;
    align-items: center;
  }
  div {
    flex: 2;
    padding: 20px;
  }
  p {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const Button = styled.button`
  border: 1px solid gray;
  background-color: transparent;
  font-size: 16px;
  margin: 0;
`;
