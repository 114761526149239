import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../../../../index.css";

import studying from "../../../assets/regional/studying.png";
import hirring from "../../../assets/regional/growth.png";
import consulting from "../../../assets/company/handshake.png";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.4,
    },
  },
};

const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.8, // Adjust the duration to make the animation faster
    },
  },
};
const CardOne = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>01 </h1>
          <p style={{ fontSize: 22 }}>정책 연구</p>
        </div>
        <div className="img-wrapper">
          <img
            src={hirring}
            alt="support-img"
            width={"50%"}
            style={{ margin: 20 }}
          />
        </div>
      </div>
    </div>
  );
};
const CardTwo = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>02 </h1>
          <p style={{ fontSize: 22 }}>지역 혁신 컨설팅</p>
        </div>
        <div className="img-wrapper">
          <img
            src={consulting}
            alt="support-img"
            width={"50%"}
            style={{ margin: 20 }}
          />
        </div>
      </div>
    </div>
  );
};

const CardThird = () => {
  return (
    <div className="company-card-container">
      <div className="company-card-wrap">
        <div>
          <h1>03 </h1>
          <p style={{ fontSize: 22 }}>교육 훈련</p>
        </div>
        <div className="img-wrapper">
          <img
            src={studying}
            alt="support-img"
            width={"50%"}
            style={{ margin: 20 }}
          />
        </div>
      </div>
    </div>
  );
};

const CardWrapper = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const handleCardClick = (cardNumber) => {
    setSelectedCard(cardNumber);
    controls.start("visible");
  };

  return (
    <div>
      <div style={{ padding: "0 15%" }} ref={ref}>
        <h1>동행하는 유알커넥션</h1>
        <motion.ul
          className="regional-card-maincontainer"
          variants={container}
          initial="hidden"
          animate={controls}
        >
          {[
            <CardOne onClick={() => handleCardClick(1)} />,
            <CardTwo onClick={() => handleCardClick(2)} />,
            <CardThird onClick={() => handleCardClick(3)} />,
          ].map((card, index) => (
            <motion.ul key={index} variants={item}>
              {card}
            </motion.ul>
          ))}
        </motion.ul>
      </div>
    </div>
  );
};

export default CardWrapper;
