import React from "react";
import { motion } from "framer-motion";
import "../../../../index.css";

function Card({ index }) {
  return (
    <motion.div
      className="card-detail"
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 1, // Animation duration
        },
      }}
      viewport={{ once: true }}
    >
      <p style={{ color: "black", fontSize: "40px" }}>
        복잡한 비자로 <br /> 방황은 이제 그만!
      </p>
      <p style={{ color: "black", fontSize: "22px", marginLeft: "30px" }}>
        검증된 외국인 인재(국내, 해외)선발부터 비자전환까지
        <br />
        원스탑서비스를 제공합니다. 더 알아보기(기업맞춤형 외국인 고용서비스로
        이동 )
      </p>
    </motion.div>
  );
}

export default Card;
